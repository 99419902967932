import InputField from "../../../components/controls/InputField";
import SelectField from "../../../components/controls/SelectField";
import SubmitButton from "../../../components/controls/SubmitButton";
import { TbCurrencyNaira } from "react-icons/tb";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { transferService } from "../../../services/transfer.service";
import OtpModal from "../../../components/modal/OtpModal";
import ClipLoader from "react-spinners/ClipLoader";
import OtpForm from "./OtpForm";
import { useSelector } from "react-redux";

const NewPayout = () => {
  const { wallet } = useSelector((state) => state.wallet);
  const [input, setInput] = useState({
    accountNumber: "",
    bankCode: "",
    amount: "",
    remark: "",
  });
  const [error, setError] = useState({});
  const [token, setToken] = useState("");
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(null);
  const [acctInfo, setAcctInfo] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [isModalUOpen, setIsModalUOpen] = useState(false);
  const [validation, setValidation] = useState({
    validAccountNo: true,
    validBankCode: true,
    validAmount: true,
    validRemark: true,
  });
  const [amountErrorMessage, setAmountErrorMessage] = useState("");

  const checkAmount = () => {
    const inputAmount = input?.amount ? parseFloat(input.amount.replace(/,/g, "")) : 0;
    const availableBalance = wallet?.availableBalance
      ? parseFloat(wallet.availableBalance.toString().replace(/,/g, ""))
      : 0;

    if (inputAmount > availableBalance) {
      setAmountErrorMessage("Insufficient Amount balance");
    } else {
      setAmountErrorMessage("");
    }
  };

  const inputHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;
  
    if (name === "amount") {
      // Only allow numbers and decimal points to be entered
      value = value.replace(/[^0-9.]/g, "");
  
      // Prevent entering more than one decimal point
      const decimalCount = (value.match(/\./g) || []).length;
      if (decimalCount > 1) {
        value = value.replace(/\.+$/, ""); // Remove the extra decimal point
      }
  
      // Split into integer and decimal parts
      const parts = value.split(".");
  
      // Add commas to the integer part for thousands formatting
      if (parts[0]) {
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
  
      // Rejoin the parts (with the decimal point if present)
      value = parts.join(".");
  
      setAmountErrorMessage("");
    }
  
    // Update the state with the formatted value
    setInput({ ...input, [name]: value });
  
    // Perform validation as before
    setValidation((prevValidation) => ({
      ...prevValidation,
      [name]: true,
    }));
  
    // Additional amount checking logic
    if (name === "amount") {
      checkAmount();
    }
  };
  
  
  

  useEffect(() => {
    checkAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllBanks = () => {
    transferService.getBanks(35).then((result) => {
      setBanks(result.data.data);
    });
  };

  useEffect(() => {
    getAllBanks();
  }, []);

  const getAccountInfo = (value) => {
    const request = {
      accountNumber: value,
      bankCode: input?.bankCode,
    };
    setLoading(true);
    transferService
      .getAccountInfo(request)
      .then((result) => {
        if (result.data.success) {
          let info = result.data.data;
          setAcctInfo(info);
        } else {
          toast.error(`${result.data.message}, the Otp expires in 10 minutes..`);
        }
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.response?.data.message ?? e.message);
        setLoading(false);
      });
  };

  const handleModalUClose = () => setIsModalUOpen(false);

  const bankTransfer = () => {
    const areAllFieldsValid =
      validation.validAccountNo &&
      validation.validBankCode &&
      validation.validAmount &&
      validation.validRemark;

    if (areAllFieldsValid) {
      setIsProcessing(true);
      transferService
        .transferRequest(input)
        .then((result) => {
          if (result && result.data && result.data.success) {
            setToken(result.data.token);
            setTimeout(() => {
              setIsModalUOpen(true);
            }, 300);

            toast.success(result.data.message);
          } else {
            toast.error(result.data.message);
          }
        })
        .catch((error) => {
          toast.error(error.response?.data.message ?? error.message);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      toast.error("All fields are required!");
    }
  };

  return (
    <div className="flex flex-col space-y-4 py-4 px-8">
      <div className="w-full">
        <SelectField
          name="bankCode"
          id="bankCode"
          label="Bank Name"
          value={input.bankCode}
          onChange={(e) => {
            const capitalizedValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
            setInput({ ...input, bankCode: capitalizedValue });
          }}
        >
          <option value="" key="default">
            Bank Name
          </option>
          {banks.map((bank, index) => (
            <option key={index} className="dark:bg-white" value={bank.institutionCode}>
              {bank.institutionName}
            </option>
          ))}
        </SelectField>
      </div>
      <div>
        <InputField
          value={input.accountNumber}
          type="number"
          label="Account Number"
          name="accountNumber"
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d{0,11}$/.test(value)) {
              setInput({ ...input, accountNumber: value });
            } else {
              setError({
                ...error,
                accountNumber: "Please enter a valid Account Number (up to 11 digits).",
              });
            }
            if (value.length > 9) {
              getAccountInfo(value);
            }
          }}
        />
        {loading ? (
          <div className="flex justify-start w-full h-full">
            <ClipLoader color="#E4AA3D" size={40} data-testid="loader" />
          </div>
        ) : (
          <span className="text-primary text-md capitalize py-4">
            <p>{acctInfo?.accountName ?? "Reciever Name"}</p>
          </span>
        )}
      </div>

      <div>
        <InputField
          value={input.amount}
          type="text"
          label="Amount"
          name="amount"
          onChange={inputHandler}
          leftIcon={<TbCurrencyNaira size={20} />}
        />
        <div className="flex flex-row justify-between py-3">
          <p className="text-red text-md">{amountErrorMessage}</p>
          <div className={`text-primary text-md flex justify-end`}>₦{wallet?.availableBalance ?? "0.00"}</div>
        </div>
      </div>
      <div>
        <InputField
          value={input.remark}
          type="text"
          label="Remark"
          name="remark"
          onChange={inputHandler}
        />
      </div>
      <div className="my-2">
        <SubmitButton disabled={amountErrorMessage !== ""} onClick={bankTransfer}>
          <span className="text-white font-semibold">
            {isProcessing ? <ClipLoader color="#fff" size={30} data-testid="loader" /> : "Pay"}
          </span>
        </SubmitButton>
      </div>
      <OtpModal
        title="Verify OTP"
        open={isModalUOpen}
        onClose={handleModalUClose}
        width={400}
        height={350}
      >
        <OtpForm token={token} reSend={bankTransfer} onClose={handleModalUClose} />
      </OtpModal>
    </div>
  );
};

export default NewPayout;
